import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import imgShowBlu from "../../../styles/images/show_blu.png";
import BlueSubjectForm from './NewBlueSubjectForm';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import getText from './labels';
import Modal from "react-bootstrap/Modal";
import BlueHistoryModal from './BlueHistoryModal';
import { BtnEmpty } from '../../shared/BtnEmpty';
import list from "../../../styles/images/elenco.png";
import * as actions from "../../../actions";
import * as styles from "../../../config/styles";
import '../../../styles/dac7NewStyles.css';

export default function BlueSubjectList(props) {
    const [showModal, setShowModal] = useState(false);
    const [indexShow, setIndexShow] = useState(0);
    const [showHistory, setShowHistory] = useState(false);
    const subjects = useSelector(state => state.blueSubjects.data);
    const lang = useSelector(state => state.blueLanguage.language)
    const labels = getText(lang);
    const dispatch = useDispatch();


    const showDetail = (index) => {
        if (!showModal) {
            setShowModal(!showModal);
            setIndexShow(index);
        } else {
            if (indexShow !== index) {
                setShowModal(!showModal);
                setIndexShow(index);
                setShowModal(showModal);
            } else {
                setShowModal(!showModal);
                setIndexShow(index);
            }
        }
    };

    const handleHistoryClick = (subjectId) => {
        dispatch(actions.getBlueSearchEvents(subjectId));
        setShowHistory(true);
    }

    const handleHistoryClose = () => {
        setShowHistory(false);
        dispatch(actions.clearBlueSearchEvents());
    }
    const onCheckClick = (e) => {

        if (e.target.checked) {
            checkAll()
        } else {
            uncheckAll();
        }
    }

    const onCheckBoxClick = (e, subject) => {
        let newList = props.selectedSubjects;
        if (e.target.checked) {
            newList = [...props.selectedSubjects, subject]
            props.setSelectedSubjects(subjectList => [...subjectList, subject]);
        } else {
            newList = newList.filter(blueSubject => blueSubject.id !== subject.id)
            props.setSelectedSubjects(props.selectedSubjects.filter(blueSubject => blueSubject.id !== subject.id))
        }

        if (newList.length === subjects?.length) {
            document.getElementById('checkboxAllRelationshipId').checked = true;
        } else {
            document.getElementById('checkboxAllRelationshipId').checked = false;
        }





    }

    const checkAll = () => {
        let subjectSelected = [];
        for (let subject in subjects) {
            document.getElementById(`checkboxSubjectDetails${subjects[subject].id}`).checked = true;
            subjectSelected.push(subjects[subject])
        }
        props.setSelectedSubjects(subjectSelected)
    }

    const uncheckAll = () => {
        for (let subject in subjects) {
            document.getElementById(`checkboxSubjectDetails${subjects[subject].id}`).checked = false;
        }
        props.setSelectedSubjects([])

    }

    const renderRows = () => {
        return subjects?.map((subject, index) => {
            const idCheckbox = `checkboxSubjectDetails${subject.id}`;
            const link = props.licenseExpired ? "#" : `/app/blue/search/${subject.id}`;
            return (
                <React.Fragment key={`blueSubjectRow${subject.id}`}>
                    <div
                        className={'text-start row-table' + (showModal && indexShow === index ? ' bg-blue bold' : '')}
                        id={`subject-${subject.id}`}
                    >
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                id={idCheckbox}
                                onClick={(e) => onCheckBoxClick(e, subject)}
                            />
                        </div>
                        <Link className={`col-lg-2 ${styles.rowLink}`} to={link}>{subject.fiscalCode}</Link>
                        <Link className={`col-lg-4 ${styles.rowLink}`} to={link}>{subject.denomination} </Link>
                        <Link className={`col-lg-3 ${styles.rowLink}`} to={link}>{subject.email}</Link>
                        <div className='col-lg-1 div-td text-truncate text-center text-decoration-none text-body' onClick={() => showDetail(index)}>                            <img src={imgShowBlu} />
                        </div>
                        <div className='col-lg-1 div-td text-truncate text-center text-decoration-none text-body' onClick={() => handleHistoryClick(subject.id)}>
                            <img src={list} alt='Visualizza storico ricerche'/>
                        </div>
                    </div>
                    <TransitionGroup component={null}>
                        {showModal && indexShow === index ?
                            <CSSTransition
                                in={showModal}
                                timeout={300}
                                classNames="visible"
                                unmountOnExit>
                                <div className="row-detail bg-blue">
                                    <div className="col-md-12 col-lg-12 text-start p-0">
                                        {<BlueSubjectForm
                                            key={`subject-${subject.id}`}
                                            subject={subject}
                                            handleCloseModal={() => showDetail(index)}
                                            getSubjects={() => props.getSubjects()}
                                            {...props}
                                        />}
                                    </div>
                                </div>
                            </CSSTransition>

                            : ''}
                    </TransitionGroup>

                    <Modal
                        backdrop="static"
                        show={showHistory}
                        onHide={() => { handleHistoryClose() }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        scrollable
                        >
                        <div className="bar"></div>
                        <Modal.Header closeButton className="ps-3">
                            <Modal.Title id="contained-modal-title-vcenter">
                                <h2>STORICO RICERCHE</h2>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <BlueHistoryModal />
                        </Modal.Body>
                        <Modal.Footer>
                            <BtnEmpty text="ANNULLA" handlerOnClick={() => { handleHistoryClose() }} />
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
            )

        })
    }


    return (
        <div className="col-lg-12 ps-0">
            <div className="row me-0 ms-0">
                <div className="col-lg-12 ps-0 pe-0">
                    <div className="text-center header-table">
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                id='checkboxAllRelationshipId'
                                onClick={(e) => onCheckClick(e)}
                            />
                        </div>
                        <div className='col-lg-2 div-td '><strong>{labels.FISCALCODE}</strong> </div>
                        <div className='col-lg-4 div-td '><strong>{labels.DENOMINATION}</strong> </div>
                        <div className='col-lg-3 div-td '><strong>{labels.EMAILADDRESS}</strong> </div>
                        <div className='col-lg-1 div-td' ><strong>{labels.DETAIL}</strong></div>
                        <div className='col-lg-1 div-td' ><strong>Storico</strong></div>
                    </div>

                </div>
            </div>


            <div className="col-lg-12 ps-0 pe-0">
                {renderRows()}
            </div>
        </div>

    )

}